<template>
  <v-data-table
    v-model="selection"
    :show-select="showSelect"
    :headers="headers"
    :items="items"
    :items-per-page="limit"
    :server-items-length="totalResults"
    :item-key="itemKey"
    :class="datatableClass"
    :loading="loading"
    :sort-by="sortBy"
    :options="datatableOptions"
    :no-data-text="noDataText"
    @input="(rows) => update_selected_rows(rows)"
    @update:items-per-page="on_limit_change"
    @update:sort-by="on_sort"
    @update:sort-desc="on_sort_asc"
    @update:options="on_options_change"
    @pagination="on_page_change"
    @click:row="on_row_click"
  >
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>
<script>
/**
 * This component is a v-data-table rigged to work with cursor-based sorting and pagination.
 * If your API does not follow its query param naming convention or cursor format, adjust as necessary.
 * The component assumes `asc` and `desc` as valid sorting directions.
 * Usage of state management is required for persistent and consistent results.
 * All usual v-data-table slots can be used without any change in behaviour.
 * Read through the prop list for implementation instructions -
 * descriptions are only added to props out of the usual v-data-table scope
 */
export default {
  name: "CursorPaginatedDatatable",
  data() {
    return {
      limit: null,
      current_page: 1,
      selection: [],
    };
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    filters: {
      // state value containing { name: value } query params used by the `getItems` function
      type: Object,
      required: true,
    },
    defaultLimit: {
      type: Number,
      default: 10,
    },
    totalResults: {
      type: Number,
      required: true,
    },
    sortBy: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    nextPage: {
      // next page cursor returned by the API, `next__value` format
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    prevPage: {
      // previous page cursor returned by the API, `prev__value` format
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    nextPageSorting: {
      // next sorted result page cursor returned by the API, `column__direction__value` format
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    prevPageSorting: {
      // previous sorted result page cursor returned by the API, `column__direction__value` format
      validator: (prop) => typeof prop === "string" || prop === null,
      required: true,
    },
    datatableOptions: {
      // used as a value for `options.sync` as in a standard v-data-table
      type: Object,
      required: true,
    },
    updateOptions: {
      // an action updating the passed options
      type: Function,
      required: true,
    },
    getItems: {
      // an action fetching datatable items using the `filters` prop as query params
      type: Function,
      required: true,
    },
    addFilter: {
      // an action mutating `filters` by adding a new { name: value } pair
      type: Function,
      required: true,
    },
    resetSortedDatatable: {
      // an action accepting a string value and assigning it to `prev_page_sorting`, while setting all other cursors to null
      type: Function,
      required: true,
    },
    clearSelectionTrigger: {
      // toggling this boolean will deselect all of the datatable's rows
      type: Boolean,
      default: false,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No data available",
    },
    datatableClass: {
      // the class to assign to v-data-table
      type: String,
      default: "",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    options: {
      get() {
        return this.datatableOptions;
      },
      set(options) {
        this.updateOptions(options);
      },
    },
  },
  methods: {
    set_local_variables() {
      this.limit = this.filters["limit"] || this.defaultLimit;
      this.current_page = this.datatableOptions["page"] || 1;
    },
    async on_sort(event, direction = "desc") {
      this.addFilter({ cursor: null });
      if (!event) {
        this.$emit("update-sort-by", null);
        this.addFilter({ sorting_cursor: null });
        await this.getItems();
        return;
      }
      this.$emit("update-sort-by", event);
      const sorting_cursor = [event, direction, ""].join("__");
      this.addFilter({ sorting_cursor });
      if (this.current_page === 1) {
        this.getItems();
      } else {
        this.resetSortedDatatable(sorting_cursor);
      }
    },
    on_sort_asc(event) {
      if (event[0]) this.on_sort(this.sortBy, "asc");
    },
    async on_page_change(event) {
      this.selection = [];
      if (event.page == this.current_page) return;
      const [cursor, sorting_cursor] =
        event.page > this.current_page
          ? [this.nextPage, this.nextPageSorting]
          : [this.prevPage, this.prevPageSorting];
      this.addFilter({ cursor });
      this.addFilter({ sorting_cursor });
      this.current_page = event.page;
      await this.getItems();
    },
    on_limit_change(limit) {
      this.limit = limit === -1 ? 9999 : limit;
      this.addFilter({ limit: this.limit });
      this.addFilter({ cursor: null });
      if (this.filters["sorting_cursor"]) {
        const sorting_params = this.filters["sorting_cursor"].split("__");
        const sorting_cursor = [sorting_params[0], sorting_params[1], ""].join(
          "__"
        );
        this.resetSortedDatatable(sorting_cursor);
      }
      if (this.current_page === 1) {
        this.getItems();
      }
    },
    on_options_change(options) {
      this.updateOptions(options);
    },
    on_row_click(event) {
      this.$emit("click:row", event);
    },
    update_selected_rows(rows) {
      this.$emit("update-selected-rows", rows);
    },
  },
  created() {
    this.set_local_variables();
    if (!this.items.length) {
      this.getItems();
    }
  },
  watch: {
    clearSelectionTrigger() {
      this.selection = [];
    },
  },
};
</script>
