var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CursorPaginatedDatatable',{attrs:{"headers":_vm.headers,"items":_vm.datatable_bookings,"filters":_vm.datatable_filters,"total-results":_vm.total_results,"sort-by":_vm.sort_by,"next-page":_vm.next_page,"prev-page":_vm.prev_page,"next-page-sorting":_vm.next_page_sorting,"prev-page-sorting":_vm.prev_page_sorting,"datatable-options":_vm.datatable_options,"update-options":_vm.set_datatable_options,"get-items":_vm.get_datatable_bookings,"add-filter":_vm.add_datatable_filter,"reset-sorted-datatable":_vm.reset_datatable_with_sorting,"loading":_vm.is_datatable_fetching,"no-data-text":"No bookings match your search","show-select":true,"datatable-class":"br-8","clear-selection-trigger":_vm.datatable_clear_selection_trigger},on:{"update-sort-by":function (v) { return (_vm.sort_by = v); },"update-selected-rows":_vm.select_bookings,"click:row":function (b) { return _vm.select_booking(b); }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.start_at).utc().format("DD/MM/YYYY"))+" ")]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.start_at).utc().format("HH:mm a"))+" ")]}},{key:"item.guest_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.extra_guest_count ? ((item.guest_count) + " + " + (item.extra_guest_count)) : item.guest_count)+" ")]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('span',[_vm._v(_vm._s(_vm.get_booking_user_name(item)))]),(item.notes)?_c('v-tooltip',{attrs:{"top":"","max-width":_vm.$vuetify.breakpoint.mdAndUp ? "30%" : "50%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"},domProps:{"textContent":_vm._s('$notes')}},on))]}}],null,true)},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.notes))])]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"noselect booking-status-chip",class:{
        disabled: [_vm.BOOKING_STATUS.NO_SHOW].includes(item.status),
      },attrs:{"color":_vm.status_map[item.status].color,"outlined":_vm.status_map[item.status].outlined}},[_c('v-icon',{attrs:{"size":"18","color":_vm.status_map[item.status].icon_color},domProps:{"textContent":_vm._s(_vm.status_map[item.status].icon)}}),_c('span',{staticClass:"pl-2 text-left outlined"},[_vm._v(" "+_vm._s(_vm.status_map[item.status].text)+" ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }