<template>
  <v-container fluid class="py-0">
    <v-row class="secondary px-4 br-8 pt-0 pb-2">
      <v-col cols="12" md="6">
        <v-text-field
          v-model="search"
          label="Name / Email"
          placeholder="Search by Name / Email"
          hide-details
          clearable
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-menu
          v-model="show_from_datepicker"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formatted_from_date"
              class="px-1"
              label="From"
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="from_date_rules"
            ></v-text-field>
          </template>
          <v-date-picker
            class="booking-date-picker"
            v-model="from_date"
            show-current="false"
            first-day-of-week="1"
            color="primary"
            :header-date-format="
              (v) => {
                return moment(v, `YYYY-MM`).format(`MMMM, YYYY`);
              }
            "
            no-title
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-menu
          v-model="show_to_datepicker"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formatted_to_date"
              class="px-1"
              label="To"
              hide-details
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="to_date_rules"
            ></v-text-field>
          </template>
          <v-date-picker
            class="booking-date-picker"
            v-model="to_date"
            show-current="false"
            first-day-of-week="1"
            color="primary"
            :header-date-format="
              (v) => {
                return moment(v, `YYYY-MM`).format(`MMMM, YYYY`);
              }
            "
            no-title
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
          v-model="_status_filter"
          :items="BOOKING_STATUS_ITEMS"
          class="px-1"
          multiple
          label="Status"
          placeholder="Filter by Status"
          hide-details
          clearable
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="_status_filter.length === 1">{{ item.text }}</span>
            <span v-if="index === 1">
              {{ _status_filter.length }} selected</span
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from "moment";

import { datepicker_range_valid } from "@/utils/form_val_rules";
import { mapActions, mapState } from "vuex";
import { BOOKING_STATUS_ITEMS } from "@/shared/const";

export default {
  name: "BookingManagementFilters",
  data() {
    return {
      moment,
      BOOKING_STATUS_ITEMS,
      show_from_datepicker: false,
      show_to_datepicker: false,
      from_date_rules: [
        (v) => datepicker_range_valid(v, this.formatted_to_date, "DD/MM/YYYY"),
      ],
      to_date_rules: [
        (v) =>
          datepicker_range_valid(this.formatted_from_date, v, "DD/MM/YYYY"),
      ],
    };
  },
  computed: {
    ...mapState("TableBookingStore", ["datatable_filters"]),
    search: {
      get() {
        return this.datatable_filters?.autocomplete || "";
      },
      set(autocomplete) {
        this.add_datatable_filter({ autocomplete });
      },
    },
    from_date: {
      get() {
        return moment(this.datatable_filters?.start_gte).format("YYYY-MM-DD");
      },
      set(start_gte) {
        start_gte += "T00:00:00";
        this.add_datatable_filter({ start_gte });
      },
    },
    to_date: {
      get() {
        return moment(this.datatable_filters?.start_lt).format("YYYY-MM-DD");
      },
      set(start_lt) {
        start_lt += "T23:59:59";
        this.add_datatable_filter({ start_lt });
      },
    },
    _status_filter: {
      get() {
        return this.datatable_filters?.booking_status?.split(",") || null;
      },
      set(booking_status) {
        this.add_datatable_filter({ booking_status });
      },
    },
    formatted_from_date() {
      return moment(this.from_date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    formatted_to_date() {
      return moment(this.to_date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapActions("TableBookingStore", ["add_datatable_filter"]),
  },
};
</script>
