<template>
  <v-btn
    :loading="loading"
    :disabled="loading"
    @click="generate_csv"
    rounded
    color="primary"
    v-bind="$attrs"
  >
    Download CSV</v-btn
  >
</template>
<script>
/**
 * This component accepts a fetching function as well as filters,
 * applies a limit of 9999, fetches results and converts them to a csv file.
 * The csv-format prop must be an object containing key-value pairs,
 * where the key is the name of the property and the value is a function
 * accepting an item and returning a value in the desired format
 */
import moment from "moment";

export default {
  name: "AsyncDownloadCSV",
  props: {
    getItems: {
      type: Function,
      required: true,
    },
    csvFormat: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    headers: {
      type: Object,
      required: true,
    },
    file_name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async generate_csv() {
      this.loading = true;
      let items = await this.get_all_items();
      items = this.format_items(items);
      await this.export_csv_file(this.headers, items, this.file_name);
      this.loading = false;
    },
    async get_all_items() {
      try {
        const {
          data: { items: items },
        } = await this.getItems({ ...this.filters, limit: 9999 });
        return items;
      } catch (error) {
        console.error(error);
      }
    },
    format_items(items) {
      return items.map((i) => {
        let row = {};
        for (const [key, value] of Object.entries(this.csvFormat)) {
          let new_value = {};
          new_value[key] = value(i);
          row = { ...row, ...new_value };
        }
        return row;
      });
    },
    convert_to_csv(data) {
      var array = typeof data != "object" ? JSON.parse(data) : data;
      let str = "";

      for (let i = 0; i < array.length; i++) {
        let line = "";
        for (let index in array[i]) {
          if (line != "") line += ",";

          line += `"${array[i][index]}"`;
        }

        str += line + "\r\n";
      }

      return str;
    },
    export_csv_file(headers, items, fileName) {
      if (headers) {
        items.unshift(headers);
      }

      let jsonObject = JSON.stringify(items);

      let csv = this.convert_to_csv(jsonObject);

      let csv_name =
        fileName + `_${moment().format("DD-MM-YY_HH-mm_a")}` + ".csv" ||
        "export.csv";

      let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, csv_name);
      } else {
        let link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", csv_name);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      items.shift(headers);
    },
  },
};
</script>
