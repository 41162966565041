<template>
  <div class="d-flex justify-end">
    <v-icon
      color="dark-grey"
      class="mr-5"
      v-text="`mdi-printer`"
      @click="$emit('print')"
    />
    <v-btn color="dark-grey" text rounded @click="$emit('close')">
      Close
    </v-btn>
    <v-menu v-if="actions.length && !loading" content-class=" br-16">
      <template v-slot:activator="{ on }">
        <v-btn v-bind="props" rounded v-on="on">
          <span>{{ button_text }} </span
          ><v-icon v-if="!loading" class="ml-2">mdi-chevron-down</v-icon
          ><v-progress-circular v-else indeterminate color="primary" />
        </v-btn>
      </template>
      <v-list class="py-0" dense>
        <v-list-item
          v-for="action in actions"
          :key="action.text"
          @click="action.callback()"
        >
          <v-list-item-title class="overflow-visible">
            <div class="d-flex align-center">
              <v-icon
                small
                :color="action.icon_color"
                class="mr-2"
                v-text="action.icon"
              />
              <span v-html="action.text" class="mr-2" />
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn v-else class="mx-1 flex-shrink-1" disabled
      >{{ button_text
      }}<v-progress-circular
        v-if="loading"
        :size="15"
        indeterminate
        color="white"
      ></v-progress-circular
    ></v-btn>
    <v-dialog v-model="confirm_cancel_dialog" width="500">
      <v-card class="br-16">
        <v-card-title class="primary white--text text-left">
          <p class="text-h3 font-weight-bold">Cancel this booking?</p>
        </v-card-title>

        <v-card-text>
          This action will send a cancel email to the customer.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn color="grey" text @click="confirm_cancel_dialog = false">
            close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="
              $emit('update_booking', 'cancelled');
              confirm_cancel_dialog = false;
            "
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BOOKING_STATUS_CHIP_DATA } from "@/shared/const.js";

export default {
  name: "BookingDetailActions",
  props: {
    booking: {
      type: Object,
      default: null,
    },
    loading: { type: Boolean, default: false },
    // Activator button (v-btn) props
    props: {
      type: Object,
      default: null,
    },
    button_text: {
      type: String,
      default: "Update Status",
    },
  },
  data() {
    return {
      confirm_cancel_dialog: false,
      BOOKING_STATUS_CHIP_DATA,
    };
  },
  computed: {
    actions: function () {
      const actions = [];
      this.parse_can_mark_as("new", actions);
      this.parse_can_mark_as("confirmed", actions);
      this.parse_can_mark_as("arrived", actions);
      this.parse_can_mark_as("seated", actions);
      this.parse_can_mark_as("completed", actions);
      this.parse_can_mark_as("no_show", actions);
      this.parse_can_mark_as("cancelled", actions);
      return actions;
    },
  },
  methods: {
    parse_can_mark_as(status, actions) {
      const component = this;
      const booking = this.booking;
      const status_map = this.BOOKING_STATUS_CHIP_DATA[status];

      const can_mark = booking.status !== status;

      const callback = function () {
        if (status == "cancelled") {
          component.confirm_cancel_dialog = true;
        } else {
          component.$emit("update_booking", status);
        }
      };
      if (can_mark && booking.status !== "cancelled") {
        actions.push({
          text: `Mark as <b>${status_map.text}</b>`,
          icon: status_map.icon,
          icon_color: status_map.icon_color,
          color: status_map.color,
          callback,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__title ::v-deep .status-indicator {
  height: 20px;
  width: 20px;
}
</style>
