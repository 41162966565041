<template>
  <div class="booking-management-wrapper pa-3">
    <BookingManagementFilters />
    <BookingManagementActionBar
      @adding-walk-in="(walk_in) => (adding_walk_in = walk_in)"
    />
    <BookingDatatable />
    <NewBookingDialog v-if="show_new_booking" :walk-in="adding_walk_in" />
    <ViewBookingDialog v-if="selected_booking" @close="select_booking(null)" />
    <BookingUpdateSnackbar />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { get_ms_until_multiple_of_minutes } from "@/utils/date_utils";
import BookingManagementFilters from "./components/BookingManagementFilters.vue";
import BookingManagementActionBar from "./components/BookingManagementActionBar.vue";
import BookingDatatable from "./components/BookingDatatable.vue";
import NewBookingDialog from "./components/NewBookingDialog.vue";
import BookingUpdateSnackbar from "./components/BookingUpdateSnackbar";
import ViewBookingDialog from "@/views/dashboard/components/ViewBookingDialog";

export default {
  name: "BookingManagement",
  data() {
    return {
      adding_walk_in: false,
      walk_in_availability_timeout: null,
      walk_in_availability_interval: null,
    };
  },
  components: {
    BookingManagementFilters,
    BookingManagementActionBar,
    BookingDatatable,
    NewBookingDialog,
    ViewBookingDialog,
    BookingUpdateSnackbar,
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("TableBookingStore", ["selected_booking", "show_new_booking"]),
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "select_booking",
      "get_zones",
      "get_tables",
      "get_available_dates",
      "get_available_walk_in_dates",
    ]),
  },
  mounted() {
    this.get_available_dates(this.shop_id);
    this.get_available_walk_in_dates(this.shop_id);
    this.get_zones(this.shop_id);
    this.get_tables(this.shop_id);
    const ms_until_full_quarter = get_ms_until_multiple_of_minutes(15);
    this.walk_in_availability_timeout = setTimeout(() => {
      this.get_available_walk_in_dates(this.shop_id);
      this.walk_in_availability_interval = setInterval(
        () => this.get_available_walk_in_dates(this.shop_id),
        1000 * 60 * 15
      );
    }, ms_until_full_quarter);
  },
  destroyed() {
    if (this.walk_in_availability_timeout)
      clearTimeout(this.walk_in_availability_timeout);
    if (this.walk_in_availability_interval)
      clearInterval(this.walk_in_availability_interval);
  },
};
</script>

<style lang="scss" scoped>
.booking-management-wrapper ::v-deep {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  grid-row-gap: 28px;
  .v-icon {
    &.mdi-checkbox-marked {
      color: var(--v-primary-base);
    }
    &.mdi-minus-box {
      color: var(--v-primary-base);
    }
  }
}
</style>
