<template>
  <v-btn
    v-if="customer_id"
    :loading="is_loading"
    rounded
    color="accent"
    :disabled="!action_callback"
    class="ml-3"
    @click="action_callback"
    >{{ action_text }}</v-btn
  >
</template>

<script>
import { get_payment_intent, collect_no_show_charge } from "@/requests";

export default {
  name: "BookingPaymentIntentControl",
  props: {
    customer_id: {
      type: String,
      required: false,
    },
    intent_id: {
      type: String,
      required: false,
    },
    booking_id: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    is_loading: false,
    intent: null,
  }),
  computed: {
    action_text() {
      const intent = this.intent;
      if (this.is_loading) return "Loading ...";
      else if (!intent) return "Capture deposit";
      else if (intent.status === "requires_capture") {
        if (!this.time_left) return "Charge expired";
        return `Collect £${intent.amount_capturable / 100} ${this.time_left}`;
      } else if (intent.status === "succeeded")
        return `£${intent.amount_received / 100} collected`;
      else return "No payment";
    },
    action_callback() {
      const intent = this.intent;
      if (this.is_loading) return false;
      else if (!intent) return this.capture;
      else return false;
    },
  },
  async mounted() {
    try {
      if (!this.intent_id) return;
      this.is_loading = true;
      const { data: intent } = await get_payment_intent(this.intent_id);
      this.intent = intent;
      console.log(intent);
    } finally {
      this.is_loading = false;
    }
  },
  methods: {
    async capture() {
      try {
        if (!this.booking_id) return;
        this.is_loading = true;
        const { data: intent } = await collect_no_show_charge(this.booking_id);
        this.intent = intent;
        console.log(intent);
      } finally {
        this.is_loading = false;
      }
    },
  },
};
</script>
