<template>
  <div>
    <v-menu content-class=" br-16">
      <template v-slot:activator="{ on }">
        <v-btn v-bind="props" rounded v-on="on" :disabled="disabled">
          <span>{{ button_text }} </span
          ><v-icon v-if="!loading" class="ml-2">mdi-chevron-down</v-icon
          ><v-progress-circular v-else indeterminate color="primary" />
        </v-btn>
      </template>
      <v-list class="py-0" dense>
        <v-list-item
          v-for="action in actions"
          :key="action.text"
          @click="update_to(action.status)"
        >
          <v-list-item-title class="overflow-visible">
            <div class="d-flex align-center">
              <v-icon
                small
                :color="action.icon_color"
                class="mr-2"
                v-text="action.icon"
              />
              <span v-html="action.text" class="mr-2" />
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="show_update_dialog" width="500">
      <v-card class="br-16">
        <v-card-title class="primary white--text text-left">
          <p class="text-h3 font-weight-bold">{{ dialog_header }}</p>
        </v-card-title>

        <v-card-text>
          <p class="text-h4 pt-1">{{ dialog_text }} <br /></p>
          <p v-if="cancelled_text" class="pt-1 text-subtitle-1">
            {{ cancelled_text }} <br />
          </p>
          <p v-if="redundant_text" class="text-subtitle-1">
            {{ redundant_text }} <br />
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="justify-end">
          <v-btn color="grey" text @click="show_update_dialog = false">
            close
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="submit_bulk_update(target_status)"
            :loading="is_sending"
          >
            {{ dialog_button_text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { BOOKING_STATUS_CHIP_DATA, BOOKING_STATUS } from "@/shared/const.js";
import { mapActions, mapState } from "vuex";

export default {
  name: "BookingBulkUpdate",
  props: {
    bookings: {
      type: Array,
      default: () => {
        return [];
      },
    },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    props: {
      type: Object,
      default: null,
    },
    button_text: {
      type: String,
      default: "Update Status",
    },
  },
  computed: {
    ...mapState("TableBookingStore", ["is_sending"]),
    actions: function () {
      const actions = [];
      Object.keys(BOOKING_STATUS_CHIP_DATA).forEach((status) =>
        this.can_update_status_to(status, actions)
      );
      return actions;
    },
  },
  data() {
    return {
      show_update_dialog: false,
      dialog_header: "",
      dialog_text: "",
      cancelled_text: "",
      redundant_text: "",
      dialog_button_text: "",
    };
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "update_bookings",
      "set_datatable_out_of_date",
      "select_bookings",
    ]),
    can_update_status_to(status, actions) {
      const status_map = BOOKING_STATUS_CHIP_DATA[status];
      const can_update = this.bookings.some(
        (b) => ![status, BOOKING_STATUS.CANCELLED].includes(b.status)
      );
      if (!this.bookings.length || !can_update) return;
      actions.push({
        text: `Mark as <b>${status_map.text}</b>`,
        icon: status_map.icon,
        icon_color: status_map.icon_color,
        color: status_map.color,
        status: status,
      });
    },
    update_to(status) {
      this.show_update_dialog = true;
      this.target_status = status;
      const cancelled_count = this.bookings.filter(
        (b) => b.status === BOOKING_STATUS.CANCELLED
      ).length;
      const redundant_count =
        status === BOOKING_STATUS.CANCELLED
          ? 0
          : this.bookings.filter((b) => b.status === status).length;
      const update_count =
        this.bookings.length - cancelled_count - redundant_count;
      if (status === BOOKING_STATUS.CANCELLED) {
        this.dialog_text = `Are you sure you want to cancel ${update_count} boooking${
          update_count > 1 ? "s" : ""
        }? This action will notify the customer${
          update_count > 1 ? "s" : ""
        } via email.`;
        this.dialog_button_text = this.dialog_header = `Cancel Booking${
          update_count > 1 ? "s" : ""
        }`;
        this.dialog_header += "?";
      } else {
        this.dialog_text = `Are you sure you want to mark ${update_count} booking${
          update_count > 1 ? "s" : ""
        } as ${BOOKING_STATUS_CHIP_DATA[status].text}?`;
        this.dialog_button_text = this.dialog_header = `Update Booking${
          update_count > 1 ? "s" : ""
        }`;
        this.dialog_header += "?";
      }
      if (cancelled_count) {
        this.cancelled_text = `${cancelled_count} of the selected bookings will not be affected as ${
          cancelled_count > 1 ? "they're" : "it's"
        } already cancelled.`;
      }
      if (redundant_count) {
        this.redundant_text = `${redundant_count} of the selected bookings will not be affected as ${
          redundant_count > 1 ? "they're" : "it's"
        } already marked as ${BOOKING_STATUS_CHIP_DATA[status].text}.`;
      }
    },
    async submit_bulk_update(status) {
      const updated_bookings = await this.update_bookings(status);
      this.show_update_dialog = false;
      if (updated_bookings.length) {
        this.set_datatable_out_of_date(true);
      }
    },
  },
  watch: {
    show_update_dialog(show) {
      if (!show) {
        this.dialog_header =
          this.dialog_text =
          this.cancelled_text =
          this.redundant_text =
          this.dialog_button_text =
            "";
      }
    },
  },
  beforeDestroy() {
    this.select_bookings([]);
  },
};
</script>
