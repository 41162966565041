<template>
  <div class="d-flex flex-wrap align-center justify-space-between px-4">
    <div class="d-flex flex-wrap align-center">
      <BookingBulkUpdate
        :bookings="selected_bookings"
        :disabled="!selected_bookings_to_update.length"
        :props="{ color: 'primary' }"
      />
      <v-btn
        rounded
        class="mr-6"
        color="yellow"
        width="148.6"
        elevation="1"
        @click="add_new_booking"
      >
        <span class="button-text">New Booking</span>
      </v-btn>
      <!-- <v-tooltip
        bottom
        content-class="custom-tooltip"
        :disabled="is_available_for_walk_in"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              rounded
              class="mr-6"
              color="primary"
              width="148.6"
              elevation="1"
              :disabled="!is_available_for_walk_in"
              @click="add_walk_in"
            >
              <span class="button-text">Walk-In</span>
            </v-btn>
          </div>
        </template>
        <span
          >Cannot accomodate a walk-in as the venue is fully booked for the
          day</span
        >
      </v-tooltip> -->
    </div>
    <div>
      <v-btn
        rounded
        class="mr-6"
        color="primary"
        width="148.6"
        elevation="1"
        :disabled="!datatable_out_of_date"
        @click="get_datatable_bookings"
      >
        <span class="button-text">Refresh Table</span>
      </v-btn>
      <AsyncDownloadCSV
        class="download-csv"
        :get-items="get_bookings"
        :csv-format="bookings_csv_format"
        :filters="{
          ...datatable_filters,
          shop_id: shop_id,
          cursor: null,
        }"
        :headers="headers_csv_format"
        file_name="bookings"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";

import { get_bookings } from "@/requests";
import AsyncDownloadCSV from "@/components/AsyncDownloadCSV";
import BookingBulkUpdate from "@/views/dashboard/pages/table_bookings/components/BookingBulkUpdate";
import { BOOKING_STATUS_CHIP_DATA } from "@/shared/const";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BookingManagementActionBar",
  components: {
    AsyncDownloadCSV,
    BookingBulkUpdate,
  },
  data() {
    return {
      moment,
      get_bookings,
      headers_csv_format: {
        status: "Status",
        date: "Date",
        start_at: "Starts",
        end_at: "Ends",
        table: "Table",
        count: "No. of Ppl",
        customer: "Customer",
        phone_no: "Phone",
        email: "Email",
        notes: "Notes",
      },
      bookings_csv_format: {
        status: (b) => BOOKING_STATUS_CHIP_DATA[b.status].text || "",
        date: (b) => moment(b.start_at).utc().format("DD/MM/YYYY") || "",
        start_at: (b) => moment(b.start_at).utc().format("HH:mm a") || "",
        end_at: (b) => moment(b.end_at).utc().format("HH:mm a") || "",
        table: (b) => b.table.name || "",
        count: (b) => b.guest_count || "",
        customer: (b) => b.user.name || "",
        phone_no: (b) => b.user.phone_no || "",
        email: (b) => b.user.email || "",
        notes: (b) => b.notes || "",
      },
    };
  },
  computed: {
    ...mapState("TableBookingStore", [
      "datatable_filters",
      "allowed_dates",
      "datatable_out_of_date",
      "selected_bookings",
    ]),
    ...mapState("AdminStore", ["shop_id"]),
    ...mapGetters("TableBookingStore", [
      "is_available_for_walk_in",
      "selected_bookings_to_update",
    ]),
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "toggle_show_new_booking",
      "get_datatable_bookings",
    ]),
    add_new_booking() {
      this.$emit("adding-walk-in", false);
      this.toggle_show_new_booking();
    },
    add_walk_in() {
      this.$emit("adding-walk-in", true);
      this.toggle_show_new_booking();
    },
  },
};
</script>
<style lang="scss" scoped>
.button-text {
  font-weight: 500;
}
.download-csv {
  background-color: var(--v-completed-base) !important;
}
</style>
