<template>
  <v-snackbar v-model="show" color="accent" timeout="5000" max-width="30">
    <div class="d-flex align-center justify-space-between br-8">
      <div class="d-flex align-center">
        <v-icon large class="mr-3" color="white">
          mdi-alert-circle-outline
        </v-icon>
        <span class="text-h4 white--text font-weight-bold">{{
          update_snackbar_text
        }}</span>
      </div>
      <v-icon class="ml-4" color="white" @click="show = false"
        >mdi-close</v-icon
      >
    </div>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "BookingUpdateSnackbar",
  computed: {
    ...mapState("TableBookingStore", [
      "update_snackbar_text",
      "show_update_snackbar",
    ]),
    show: {
      get() {
        return this.show_update_snackbar;
      },
      set(value) {
        this.set_show_update_snackbar(value);
      },
    },
  },
  methods: {
    ...mapActions("TableBookingStore", ["set_show_update_snackbar"]),
  },
  beforeDestroy() {
    this.set_show_update_snackbar(false);
  },
};
</script>
