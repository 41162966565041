<template>
  <CursorPaginatedDatatable
    :headers="headers"
    :items="datatable_bookings"
    :filters="datatable_filters"
    :total-results="total_results"
    :sort-by="sort_by"
    :next-page="next_page"
    :prev-page="prev_page"
    :next-page-sorting="next_page_sorting"
    :prev-page-sorting="prev_page_sorting"
    :datatable-options="datatable_options"
    :update-options="set_datatable_options"
    :get-items="get_datatable_bookings"
    :add-filter="add_datatable_filter"
    :reset-sorted-datatable="reset_datatable_with_sorting"
    :loading="is_datatable_fetching"
    :no-data-text="`No bookings match your search`"
    :show-select="true"
    :datatable-class="`br-8`"
    :clear-selection-trigger="datatable_clear_selection_trigger"
    @update-sort-by="(v) => (sort_by = v)"
    @update-selected-rows="select_bookings"
    @click:row="(b) => select_booking(b)"
  >
    <template v-slot:[`item.date`]="{ item }">
      {{ moment(item.start_at).utc().format("DD/MM/YYYY") }}
    </template>
    <template v-slot:[`item.start_at`]="{ item }">
      {{ moment(item.start_at).utc().format("HH:mm a") }}
    </template>
    <template v-slot:[`item.guest_count`]="{ item }">
      {{
        item.extra_guest_count
          ? `${item.guest_count} + ${item.extra_guest_count}`
          : item.guest_count
      }}
    </template>
    <template v-slot:[`item.user.name`]="{ item }">
      <div class="d-flex flex-nowrap align-center">
        <span>{{ get_booking_user_name(item) }}</span>
        <v-tooltip
          v-if="item.notes"
          top
          :max-width="$vuetify.breakpoint.mdAndUp ? `30%` : `50%`"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-text="'$notes'" color="primary" v-on="on" />
          </template>
          <span class="font-weight-medium">{{ item.notes }}</span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="noselect booking-status-chip"
        :class="{
          disabled: [BOOKING_STATUS.NO_SHOW].includes(item.status),
        }"
        :color="status_map[item.status].color"
        :outlined="status_map[item.status].outlined"
      >
        <v-icon
          size="18"
          v-text="status_map[item.status].icon"
          :color="status_map[item.status].icon_color"
        />
        <span class="pl-2 text-left outlined">
          {{ status_map[item.status].text }}
        </span>
      </v-chip>
    </template>
  </CursorPaginatedDatatable>
</template>
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";

import CursorPaginatedDatatable from "@/components/CursorPaginatedDatatable.vue";
import { BOOKING_STATUS_CHIP_DATA, BOOKING_STATUS } from "@/shared/const";

export default {
  name: "BookingDatatable",
  components: {
    CursorPaginatedDatatable,
  },
  data() {
    return {
      moment,
      status_map: BOOKING_STATUS_CHIP_DATA,
      BOOKING_STATUS,
      sort_by: "start_at",
      headers: [
        { text: "#", value: "id", align: "center" },
        { text: "Status", value: "status", align: "center", width: "9%" },
        {
          text: "Date",
          value: "date",
          align: "start",
          width: "4%",
          sortable: false,
        },
        { text: "Time", value: "start_at", align: "center", width: "12%" },
        { text: "Table", value: "table.name", align: "start", width: "11%" },
        {
          text: "No. of Ppl",
          value: "guest_count",
          align: "start",
          width: "12%",
        },
        {
          text: "Customer",
          value: "user.name",
          align: "start",
          sortable: false,
        },
        {
          text: "Phone",
          value: "user.phone_no",
          align: "start",
          width: "15%",
          sortable: false,
        },
        {
          text: "Email",
          value: "user.email",
          align: "start",
          width: "15%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("StaffStore", ["staff"]),
    ...mapState("TableBookingStore", [
      "datatable_bookings",
      "total_results",
      "is_datatable_fetching",
      "datatable_filters",
      "datatable_options",
      "next_page",
      "prev_page",
      "next_page_sorting",
      "prev_page_sorting",
      "datatable_out_of_date",
      "datatable_clear_selection_trigger",
    ]),
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "set_datatable_options",
      "add_datatable_filter",
      "get_datatable_bookings",
      "reset_datatable_with_sorting",
      "select_booking",
      "select_bookings",
      "display_update_snackbar",
      "display_queried_booking",
    ]),
    get_booking_user_name(booking) {
      let name;
      if (booking.staff_id) {
        name = this.staff.find((s) => s.id == booking.staff_id)?.name || null;
        if (name) name += " (Staff)";
      }
      if (!name) name = booking.user.name;
      return name;
    },
  },
  created() {
    if (this.$route.query?.booking_id) {
      this.display_queried_booking(this.$route.query.booking_id);
    }
  },
  watch: {
    datatable_out_of_date(out_of_date) {
      if (out_of_date)
        this.display_update_snackbar(
          "Booking data in the table may be outdated. Click 'Refresh Table' to get the newest data."
        );
    },
    selected_booking(booking) {
      if (!booking && Object.keys(this.$route.query).length) {
        this.$router.replace({ query: null });
      }
    },
    "$route.query.booking_id": {
      handler: function (booking_id) {
        if (booking_id) {
          this.display_queried_booking(this.$route.query.booking_id);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.booking-status-chip {
  &.disabled {
    border: 1px solid #bcbcbc !important;
    span {
      color: #acacac !important;
    }
  }

  ::v-deep {
    border: 1px solid #707070 !important;
    width: 120px;

    span {
      width: 100%;
      color: white;
      &.outlined {
        color: var(--v-dark-grey-base);
      }
    }
  }
}
::v-deep tr :hover {
  cursor: pointer;
}
</style>
